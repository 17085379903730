

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-head{
  position: absolute;
  background: rgb(241,155,18);
  background: linear-gradient(145deg, #f09a13 10%, #dd4c39 90%);
  height: 200px;
  width:100%;
}

.App-toolbar{
position: absolute;
right:50px
}
.Content-wrapper{
  padding-top: 70px;
  margin-left: 50px;
  margin-right: 50px;
  display: grid;
 grid-template-columns: 1fr 6fr;
  grid-template-areas:
    'header header'
    'menu main '
    'menu footer '
    ;
  /*flex-grow: 1;*/
  min-height: 100vh;
  min-width: calc(100% - 100px);

  overflow: auto;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
